import React from 'react';
import LOGO from '../../images/logo.png';

import './FooterComponent.scss';

const FooterComponent = () => {
  return (
    <div className='FooterComponent'>
      <div className="footer">
            <div className="container-fluid">
              <div className="row">
                  <div className="col-md-5">
                      <div className="footer-contact">
                          <h2>Salong Adress</h2>
                          <p><i className="fa fa-map-marker-alt"></i>Svetsarvägen 15, 17141 Solna</p>
                          <p><i className="fa fa-phone-alt"></i>0739605170</p>
                          <p><i className="fa fa-envelope"></i>gladanac.a@gmail.com</p>
                          <div className="footer-social">
                              {/* <a href="/"><i className="fab fa-twitter"></i></a> */}
                              <a href="https://www.facebook.com/AlexGlad_HairCare-103173041965929" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
                              {/* <a href="/"><i className="fab fa-youtube"></i></a> */}
                              <a href="https://www.instagram.com/alexglad_haircare/" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
                              {/* <a href="/"><i className="fab fa-linkedin-in"></i></a> */}
                          </div>
                      </div>
                  </div>
                  <div className="col-md-2">
                    <img className="logo" src={LOGO} alt="alexglad" />
                  </div>
                  <div className="col-md-5">
                      <div className="footer-link">
                          <h2>Snabblänkar</h2>
                          <a href="https://www.bokadirekt.se/places/alex-glad-hair-care-40921" target="_blank" rel="noopener noreferrer">BOKA TID</a>
                          <a href="/contact">Kontakt</a>
                          <a href="/gallery">Galleri</a>
                          <a href="/services">Tjänster</a>
                          <a href="/about">Om oss</a>
                      </div>
                  </div>
              </div>
                  
            </div>
        </div>
    </div>
  );
}

export default FooterComponent;
