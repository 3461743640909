import React from 'react';

import ABOUTUS from '../../../images/about-us.jpg';

import './AboutUsComponent.scss';

const AboutUsComponent = () => {
  return (
    <div className="AboutUsComponent">
      <div className="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-6">
              <div className="about-img">
                  <img src={ABOUTUS} alt="alexglad" />
              </div>
            </div>
            <div className="col-lg-7 col-md-6">
              <div className="section-header text-left">
                <p>Om företaget</p>
                <h2>KÄRA KUNDER</h2>
              </div>
              <div className="about-text">
                <p>
                  Varmt välkommen till "Alex Glad Hair Care" hårsalong på Svetsarvägen 15, 17141 Solna!
                </p>
                <p>
                  Här kan du få den klippningen eller den behandlingen du önskar och samtidigt koppla av i en trivsam 
                  miljö. Hos oss får du den bästa servicen och du är nöjd och glad när du lämnar salongen.
                </p>
                <p>
                  Jag, Alexandra,  har över 20 års erfarenhet inom branschen - och är väl medveten att fortbildning alltid
                  är grundläggande och utvecklande. Därför går  jag regelbundet på kurser och visningar: där hämtar 
                  jag uppfriskande inspiration, lär mig om nya trender och förvärva kunskap om dom bästa hårprodukterna på marknaden.
                </p>
                <p>
                  Allting för att mina kunder alltid ska få det bästa. 
                </p>
                <p>
                  Boka gärna en tid hos oss redan i dag, Alexglad Haircare ser framemot att få träffa dig och hälsa dig välkommen som ny kund!
                </p>
                <p>
                  OBS: Håll gärna utkik på vår Instagram <a href="https://www.instagram.com/alexglad_haircare/" target="_blank" rel="noopener noreferrer">@alexglad_haircare</a> har vi ofta intressanta nyheter och uppdaterade erbjudande för dig!
                </p>
                <p>
                  Facebook <a href="https://www.facebook.com/AlexGlad_HairCare-103173041965929" target="_blank" rel="noopener noreferrer">AlexGlad_HairCare</a>
                </p>
                <p>
                  Telefonnummer 0739605170
                </p>
                <p>
                  Med respekt,
                  Alexandra Gladanac
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>        
    </div>
  );
}

export default AboutUsComponent;
